import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  Tooltip,
  OverlayTrigger,
  Popover,
  Badge,
  Spinner,
  Alert
} from 'react-bootstrap';
import { FaPen, FaTrash } from 'react-icons/fa';
import Api from '../../services/api';
import ProjectForm from '../home/ProjectForm';
import ProjectScenarios from './ProjectScenarios';
import ProjectUsers from './ProjectUsers';
import { humanize } from '../common/helpers';
import { withSettingsStore } from '../common/settings-context';
import './Project.css';
import { FiHelpCircle } from "react-icons/fi";

import Map from '../common/Map';
import { PageHeader } from '../common/components';
import DeleteDialog from './DeleteDialog';

const ProjectHome = withSettingsStore((props) => {
  const { isInRole, id: projectId, project: initialProject } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [project, setProject] = useState(initialProject || { groups: [], tags: [] });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(!initialProject);
  const [updateError, setUpdateError] = useState([]);

  const getProject  = useCallback(async () => {
    setLoading(true);
    try {
      const projectData = await Api().projects(projectId).get({});
      setProject(projectData);
    } catch (error) {
      console.error("Failed to fetch project data", error);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    if (!initialProject) {
      getProject ();
    }
  }, [getProject]);

  const handleEditProject = async () => {
    const error = [];
    let startDate = project.started;
    let endDate = project.ended;
    if(Date.parse(startDate) > Date.parse(endDate)){
      error.push('startDate');
    }
    if(project.name === ''){
      error.push('projectName')
    }
    setUpdateError(error);
    if(error.length === 0){
      await Api().projects(projectId).update(project);
      setShowProjectDialog(false);
      getProject();
    }    
  };

  const tooltipRemove = (
    <Tooltip id="tooltip-public">
      {t('project.Remove project')}
    </Tooltip>
  );
  const tooltip = (
    <Popover>
      <Popover.Title as="h3">{t('transportation.transportation-form-info-title')}</Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('common.1. This is a link to be shared with external user.')}
          </Row>
          <Row>
            {t('common.2. Once you click on this link, you will be logged out of your profile and logged in as a Public Access User.')}
          </Row>
          <Row>
            {t('common.3. You will need to logout of the Public Access User and login again using your credentials to get access to all the projects you created.')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );


  // Group edit related
  return (
    <Container fluid>
      <Modal show={showProjectDialog} onHide={() => { setShowProjectDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('project.Edit project')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectForm project={project} onChange={(p) => setProject(p)} updateError={updateError} fromEditForm={true} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleEditProject()}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <DeleteDialog
        projectId={projectId}
        description={'Project'}
        show={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        object={project}
        deletionType={'project'}
      />

      <PageHeader
        title={project.name}
        description={project.description}
      >
        {isInRole('ProjectAdmin', projectId)
          && (
            <div>
              <Button onClick={() => { setShowProjectDialog(true); }} variant="primary" className="mr-1">
                <FaPen />
                {' '}
                {t('project.Edit project')}
              </Button>
              <OverlayTrigger trigger={("hover", "focus")} placement="top" overlay={tooltipRemove}>
                <Button variant="danger" onClick={() => { setShowDeleteDialog(true) }}>
                  <FaTrash />
                  {' '}
                  <span className="sr-only">{t('project.Remove project')}</span>
                </Button>
              </OverlayTrigger>
            </div>

          )}
      </PageHeader>

      {loading ? <div className="position-fixed text-center" style={{ left: '50%', top: '50%' }}>
        <Spinner variant="primary" animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div> :
        <Card className="mb-4">
          <Card.Body>
            <Row>
              <Col lg="2">
                <dl>
                  {project.managerName && (
                    <>
                      <dt><strong>{t('project.Project manager')}</strong></dt>
                      <dd>{project.managerName}</dd>
                    </>
                  )}
                  {project.projectNumber && (
                    <>
                      <dt><strong>{t('project.Project number')}</strong></dt>
                      <dd>{project.projectNumber}</dd>
                    </>
                  )}
                  {
                    project.organizationId && (
                      <>
                        <dt><strong>{t('project.Database Source')}</strong></dt>
                        <dd>{project.organizationId === 1 && "Finland"}</dd>
                        <dd>{project.organizationId === 2 && "Denmark"}</dd>
                        <dd>{project.organizationId === 3 && "UK"}</dd>
                        <dd>{project.organizationId === 4 && "Germany"}</dd>
                        <dd>{project.organizationId === 6 && "Major Crossings"}</dd>
                        <dd>{project.organizationId === 7 && "Sweden"}</dd>
                        <dd>{project.organizationId === 8 && "Norway"}</dd>
                      </>
                    )
                  }
                  <dt><strong>{t('project.Project type')}</strong></dt>
                  <dd>{t(`project.${props.getProjectTypeById(Number(project.type))}`)}</dd>

                  <dt><strong>{t('project.Project status')}</strong></dt>
                  <dd>{t(`project.${props.getProjectStatusById(Number(project.status))}`)}</dd>

                  <dt><strong>{t('project.Project phase')}</strong></dt>
                  <dd>{t(`project.${props.getProjectPhaseById(Number(project.phase))}`)}</dd>

                  <dt><strong>{t('project.Project started')}</strong></dt>
                  <dd></dd>

                  <dd>{humanize.date(project.started, 'date')}</dd>

                  {project.ended && (
                    <>
                      <dt><strong>{t('project.Project ended')}</strong></dt>
                      <dd>{humanize.date(project.ended, 'date')}</dd>
                    </>
                  )}


                  {project.tags.length > 0 ? (
                    <>
                      <dt><strong>{t('project.Project Tags')}</strong></dt>
                      {project.tags.map((tag, index) => (
                        <dd key={index} ><Badge pill variant="secondary">{tag}</Badge></dd>
                      ))}
                    </>
                  ) : null}


                  {project.isPublic ? <>
                    <dt> <strong>{t("project.Public Url")}</strong>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={tooltip}
                      >
                        <FiHelpCircle style={{ marginLeft: '8px', marginBottom: '2px' }} />
                      </OverlayTrigger> </dt>
                    <dd>
                      <a href={project.publicUrl}>
                        {project.publicUrl}
                      </a>
                    </dd>
                  </> : ""}






                </dl>
              </Col>
              <Col lg={10}>
                <dl>
                  <dt><strong>{t('project.Location')}</strong></dt>
                  <dd>{project.locationName}</dd>
                </dl>
                {project.locationLongitude !== undefined && (
                  <Map
                    key={`${project.locationLatitude}x${project.locationLongitude}`}
                    position={{
                      lat: project.locationLatitude,
                      lng: project.locationLongitude,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
          <hr />
          <Card.Body>
            <ProjectScenarios projectId={projectId} project={project} />
          </Card.Body>
          {isInRole('ProjectAdmin', projectId) && (
            <>
              <hr />
              <Card.Body>
                <ProjectUsers projectId={projectId} />
              </Card.Body>
            </>
          )}
        </Card>}


    </Container>
  );
});

export default ProjectHome;
