import React, {
  useState, useEffect, useCallback, Fragment, useContext
} from 'react';
import {
  FaTrash, FaPen, FaInfoCircle, FaPlus, FaTruck, FaShip,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Table,
  ButtonGroup,
  Card,
  Alert,
  Popover,
  OverlayTrigger,
  Tooltip,
  ToggleButton
} from 'react-bootstrap';
import { withSettingsStore } from '../common/settings-context';
import MaterialForm from '../database/MaterialForm';
import { Typeahead, TextField, DropDown } from '../common/components';
import Api from '../../services/api';
import { clone, humanize, renderTooltip } from '../common/helpers';
import CalculationHeader from './CalculationHeader';
import './Forms.scss';
import { SettingsContext } from '../common/settings-context';

export default withSettingsStore(({
  element,
  onChange,
  roadTransports,
  waterborneTransports,
  getUnitById,
  getUnitByCode,
  transportTypes,
  units,
  isValid,
  saveCalculations,
  organizationId,
  user,
  setDropDownError,
  dropDownError,
  ...props
}) => {
  const { t } = useTranslation();
  const { getRoadTransport, getWaterborneTransports } = useContext(SettingsContext);

  const hasElementTransports = (e) => e !== undefined && Array.isArray(e.transports);

  // New vehicle list
  const vehicleOptions = useCallback(() => roadTransports
    .filter((item) => item.organizationId === organizationId).filter((a) => a.legacyVehicle === false)
    .map((v) => ({
      ...v, longName: [
        v.name,
        v.classification,
        `${v.deadWeight.toFixed(1)} t`
      ].filter(Boolean).join(', ')
    })),
    [roadTransports]);

  const vehicleGroups = [
    {
      name: 'Recommended',
      list: vehicleOptions().filter((v) => v.recommendedVehicle == true)
    },
    { name: 'All', list: vehicleOptions() },
  ];

  // Legacy vehicle list
  const LegacyVehicleOptions = useCallback(() => roadTransports
    .filter((item) => item.organizationId === organizationId).filter((b) => b.legacyVehicle === true)
    .map((v) => ({
      ...v, longName: [
        v.name,
        v.classification,
        `${v.deadWeight.toFixed(1)} t`
      ].filter(Boolean).join(', ')
    })),
    [roadTransports]);

  const leagacyVehicleGroups = [
    { name: 'Recommended', list: LegacyVehicleOptions().filter((v) => v.classification.includes('Keskimäärin')) },
    { name: 'All', list: LegacyVehicleOptions() },
  ];

  // Waterborne Transports lists
  const vesselOptions = useCallback(() => waterborneTransports
    .filter((item) => item.organizationId === organizationId)
    .map((v) => ({
      ...v, longName: [
        v.name,
        v.source,
      ].filter(Boolean).join(', ')
    })),
    [waterborneTransports]);

  // Initializers
  const cloneElementMaterials = () => {
    const clonedMaterials = clone(element.products);
    return clonedMaterials.length > 0 ? clonedMaterials.map((m) => ({ ...m, unitId: getUnitByCode('t', organizationId)?.[0]?.id, quantity: 0, conversionId: getMatchingConversionId(m) })) : [];
  };

  const emptyTransportMaterial = () => ({
    materialId: 0,
    material: {
      name: '',
      description: '',
      emission: 0,
      unitId: 0,
      conversions: [],
      source: '',
    },
    unitId: getUnitByCode('t', organizationId)?.[0]?.id,
    name: '',
    comment: '',
    conversionFactor: 0,
    factor: 1,
    quantity: 0,
    conversionId: 0,
  });

  const emptyElementTransport = useCallback(() => ({
    name: '',
    description: '',
    code: '',
    capacity: 0,
    roadDistance: 0,
    totalDistance: 0,
    totalQuantity: 0,
    transportRoad:
      Array.isArray(vehicleOptions()) && vehicleOptions().length > 0
        ? vehicleOptions()?.[0]
        : null,
    transportRoadId:
      Array.isArray(vehicleOptions()) && vehicleOptions().length > 0
        ? vehicleOptions()?.[0]?.id
        : null,
    transportWaterborneId: null,
    totalEmission: 0,
    transportMaterials: cloneElementMaterials(),
  }), [roadTransports, waterborneTransports]);

  const emptyMaterial = useCallback(() => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    conversions: [],
    source: '',
  }), []);

  // Update Transport Road Object
  const getTransportRoadById = (id) => roadTransports.filter((rt) => (rt.id === id));
  const updateTransportRoad = () => {
    const clonedTransports = clone(element.transports).map((ct) => {
      const transportRoad = getTransportRoadById(ct.transportRoadId)[0];

      if (Array.isArray(ct.transportMaterials)) {
        ct.transportMaterials = ct.transportMaterials.map(tm => {
          if (tm.material) {
            tm.conversionId = checkForConversions(tm.material);
          }
          return tm;
        });
      }
      return {
        ...ct,
        transportRoad: transportRoad
      };
    });
    return clonedTransports;
  };

  const checkForConversions = (material) => {
    if (material === null || material === undefined) {
      return 0;
    }
    const fromUnitId = element.unitId;
    const toUnitId = units.filter((u) => u.organizationId === organizationId).find(unit => unit.symbol === 't').id; // tonnes // material.unitId

    const conversion = material.conversions.find((c) => Number(c.fromUnitId) === fromUnitId && Number(c.toUnitId) === toUnitId);
    if (conversion !== undefined) {
      return conversion.id;
    }
    return 0;
  };

  const [showMaterialForm, setShowMaterialForm] = useState(null);
  const toggleMaterialForm = (i, mi) => {
    setShowMaterialForm(showMaterialForm === `${i}-${mi}` ? null : `${i}-${mi}`);
  };
  const [editMaterial, setEditMaterial] = useState(emptyMaterial());
  const [transports, setTransports] = useState(
    hasElementTransports(element) ? updateTransportRoad() : [emptyElementTransport()],
  );
  const [selectedFilter, setSelectedFilter] = useState(transports.map(() => 0));
  const [errors, setErrors] = useState([]);

  const handleAddTransportation = () => {
    const newTransport = emptyElementTransport();
    setTransports([...transports, newTransport]);
    setSelectedFilter([...selectedFilter, 0]);
  };

  //Add new material to a transport option
  const handleTransportationMaterialSelect = (transport, transportMaterial, material) => {
    const targetTransport = transports.find((tr) => tr === transport);
    const targetMaterial = targetTransport.transportMaterials.find((tm) => tm === transportMaterial);
    if (targetMaterial !== undefined) {
      console.log("target material not undefinded");
      targetMaterial.material = material;
      targetMaterial.materialId = material.id;
      targetMaterial.name = material.name;
      targetMaterial.conversionId = getMatchingConversionId(targetMaterial);
      targetMaterial.unitId = getUnitByCode('t', organizationId)?.[0]?.id; // tonnes
    }
    setTransports([...transports]);
  };

  //Add a new transport material form
  const addNewMaterialToTransport = (transport) => {
    const targetTransport = transports.find((tr) => tr === transport);
    const newTransportMaterial = emptyTransportMaterial();
    targetTransport.transportMaterials = [...targetTransport.transportMaterials, newTransportMaterial];
    setTransports([...transports]);
  };

  const deleteMaterialFromTransport = (transport, material) => {
    setTransports((prev) => {
      const targetTransport = prev.find((tr) => tr === transport);
      targetTransport.transportMaterials = targetTransport.transportMaterials.filter((m) => m !== material);
      return [...prev];
    });
  };

  const findObject = (haystack, needle, depth) => {
    if (depth > 5) {
      return;
    }

    if (haystack === needle) {
      return haystack;
    }

    // iterate arrays
    if (Array.isArray(haystack)) {
      for (const obj of haystack) {
        const found = findObject(obj, needle, depth + 1);
        if (found !== undefined) {
          return found;
        }
      }
    }

    // iterate objects
    for (const key in haystack) {
      if (typeof haystack[key] === 'object' && haystack[key] !== null) {
        const found = findObject(haystack[key], needle, depth + 1);
        if (found !== undefined) {
          return found;
        }
      }
    }
  };

  const handleInputChange = (orig) => (obj) => (event) => {
    const { name, value } = event.currentTarget;
    setTransports((prev) => {
      const targetObject = findObject(prev, orig);
      if (targetObject !== undefined) {
        targetObject[name] = value;
      }

      return [...prev];
    });
  };

  const handleRemoveTransportation = (transport) => {
    setTransports(transports.filter((tr) => tr !== transport));
  };

  const searchMaterials = async ({ search, filter }) => {
    const results = await Api().materials().get({ search, filterByOrgId: organizationId });
    let res = results.items.map((r) => ({ ...r, unit: getUnitById(r.unitId).abbreviation }));
    switch (filter) {
      case 1:
        res = res.filter((material) => material.createdBy === user.id);
        break;
      case 2:
        res = res.filter((material) => material.createdBy === null);
        break;
      default:
        // No filtering needed for all materials
        break;
    }
    return res;
  };

  const handleFilterChange = (index, value) => {
    const updatedFilters = [...selectedFilter];
    updatedFilters[index] = value;
    setSelectedFilter(updatedFilters);
  };

  const getMatchingConversionId = useCallback((transportMaterials) => {
    if (element === undefined || element === null || transportMaterials === undefined || transportMaterials === null || transportMaterials.material === undefined || transportMaterials.material === null) {
      return 0;
    }
    const fromUnitId = element.unitId;
    const toUnitId = units.filter((u) => u.organizationId === organizationId).find(unit => unit.symbol === 't').id; // tonnes // transportMaterials.material.unitId

    const conversion = transportMaterials.material.conversions.find((c) => Number(c.fromUnitId) === fromUnitId && Number(c.toUnitId) === toUnitId);
    if (conversion !== undefined) {
      return conversion.id;
    }
    return 0;
  }, [element]);

  const getConversionComment = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found !== undefined && found !== null && found.comment !== null) {
      return found.comment;
    }
    return '';
  }, []);

  const getConversionFactor = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found) {
      let factor;
      if (found.inverse === true) {
        factor = 1 / found.factor;
      } else {
        factor = found.factor;
      }
      return (factor > 0 && factor < 0.001) ?
        factor.toExponential(3) :
        humanize.amount_long(factor, 3);
    }
    return null;
  }, []);

  //Saves the edited material
  const handleSaveEditMaterial = async (transport, material) => {
    const targetTransport = transports.find((tr) => tr === transport);
    const targetTransportMaterial = targetTransport.transportMaterials.find((m) => m.id === material.id);
    const errorLog = []
    console.log("Test 1");
    console.log(JSON.parse(JSON.stringify(material)));
    console.log("material");

    console.log(material.material.id);
    console.log("targetTransportMaterial");

    console.log(targetTransportMaterial.material.id);
    if (!editMaterial.name || editMaterial.name.trim() == '') {
      errorLog.push('name')
    }
    if (!editMaterial.source || editMaterial.source.trim() == '') {
      errorLog.push('source')
    }
    setErrors(errorLog);
    if (errorLog.length > 0) {
      return;
    }

    let response = null;
    if (editMaterial.id === undefined || editMaterial.id === null || editMaterial.id === 0) {
      console.log("editMaterial is null");
      response = await Api().materials().post(editMaterial);
    } else {
      console.log("editMaterial has been updated");
      console.log(editMaterial.id);
      response = await Api().materials(editMaterial.id).update(editMaterial);
      console.log(response);
    }
    targetTransportMaterial.materialId = response.id;
    console.log(response.id);
    console.log(targetTransportMaterial);
    targetTransportMaterial.material = response;
    targetTransportMaterial.name = response.name;
    targetTransportMaterial.value = response.value;
    targetTransportMaterial.conversionId = getMatchingConversionId(targetTransportMaterial);
    setShowMaterialForm(null);
  };

  useEffect(() => {
    getRoadTransport();
  }, [])
  useEffect(() => {
    getWaterborneTransports();
  }, [])
  useEffect(() => {
    setTransports(
      hasElementTransports(element) ? updateTransportRoad() : [emptyElementTransport()],
    );
  }, [element, emptyElementTransport]);

  useEffect(() => {
    element.transports = transports;
    onChange !== undefined && onChange(element);
  }, [element, onChange, transports]);

  // Handle Transport type change
  const handleTransportTypeChange = (transport, value) => {
    const target = transports.find((trt) => trt === transport);
    if (target !== undefined) {
      target.transportRoadId = value === 'Road transport' ? 0 : null;
      target.transportWaterborneId = value === 'Waterborne transport' ? 0 : null;
      setTransports([...transports]);
    }
  };

  // Show form fields for the new vehicles
  let showNewForm = (transport) => {
    const transportRoadIdNumber = parseInt(transport.transportRoadId);
    const foundVehicle = roadTransports.filter((u) => u.organizationId === organizationId).filter(vehicleA => vehicleA.id === transportRoadIdNumber);
    if (foundVehicle.length > 0) {
      const foundVehicleUnitId = foundVehicle[0].unitId;
      const kilometerUnit = units.filter((u) => u.organizationId === organizationId).find(unit => unit.symbol === 'km');
      if (foundVehicleUnitId === kilometerUnit.id) {
        return false;
      }
    }
    return true;
  }
  const showVehicleList = (transport) => {
    if (transport.transportRoad === undefined) {
      return false;
    }
    return transport.transportRoad?.legacyVehicle !== false;
  };

  /** Popovers */
  const popover2 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{t('transportation.transportation-form-info-title')}</Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('transportation.Enter a one way transport distance')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );

  const popover3 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.element-info-details-row-1')}
          </Row>
          <Row>
            {t('installation.element-info-details-row-2')}
          </Row>
          <Row>
            {t('installation.element-info-details-row-3')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );

  const tooltipRemove = (
    <Tooltip id="tooltip-remove-transportation">
      {t('transportation.Remove transportation')}
    </Tooltip>
  );

  const popover4 = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('transportation.popover-info-details-row-1')}
          </Row>
          <Row>
            {t('transportation.popover-info-details-row-2')}
          </Row>
          <Row>
            {t('transportation.popover-info-details-row-3')}
          </Row>
          <Row>
            {t('transportation.popover-info-details-row-4')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <CalculationHeader
        element={element}
        totalEmissionType="totalTransportEmission"
        isValid={isValid}
        saveCalculations={saveCalculations}
        organizationId={organizationId}
      >
      </CalculationHeader>

      {transports.map((transport, i) => (
        <Card key={i} className="mb-2">
          <Card.Header>
            <Row className="installation__row">
              <Col sm={8}>
                <h4 className="transport-title">
                  <span>
                    <span className="transport-type-icon">
                      {transport.transportRoadId !== null && (<FaTruck />)}
                      {transport.transportWaterborneId !== null && (<FaShip />)}
                    </span>
                    <span className="mr-2">{transport.name ? transport.name : `${t('transportation.Transportation')} ${i + 1}`}</span>
                    <span>
                      <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popover4}>
                        <FaInfoCircle />
                      </OverlayTrigger>
                    </span>
                  </span>
                </h4>
              </Col>
              <Col className="d-flex flex-column">
                <h6 className="my-1 mr-1">{t('common.Emission')}</h6>
                <span>
                  {humanize.amount_long(transport.totalEmission, 2)}
                  {/* <small>{` ${t('common.kgco2eq')}`}</small> */}
                  {' '}
                  <small style={{ textTransform: 'none' }}>
                    {getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}
                  </small>
                </span>
              </Col>
              <Col className="text-right">
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipRemove}>
                  <Button
                    variant="outline-link"
                    className="text-muted"
                    onClick={() => { handleRemoveTransportation(transport); setDropDownError(false)}}
                  >
                    <FaTrash />
                    <span className="sr-only">{t('transportation.Remove transportation')}</span>
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col xs={2}>
                <Form.Group>
                  <Form.Label>
                    {t('Type of transport')}
                  </Form.Label>
                  <select
                    className="form-control form-control-md"
                    id={`transportType-${transport.id}`}
                    value={transport.transportWaterborneId === null ? 'Road transport' : 'Waterborne transport'}
                    onChange={(e) => handleTransportTypeChange(transport, e.target.value)}
                  >
                    {transportTypes.map((transportType) => (
                      <option key={transportType} value={transportType}>
                        {t(transportType)}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>

              {(transport.transportRoadId !== null || (transport.transportRoadId === null && transport.transportWaterborneId === null)) && (
                <>
                  <Col xs={showNewForm(transport) ? 6 : 2}>
                    <Form.Group>
                      <Form.Label>
                        {t('database.Vehicle')}
                      </Form.Label>
                      <DropDown
                        object={transport}
                        prop="transportRoadId"
                        onChange={handleInputChange(transport)}
                        options=
                        {
                          showVehicleList(transport) ? leagacyVehicleGroups : vehicleGroups
                        }
                        optiontext="longName"
                        optionvalue="id"
                        isGrouped
                        setDropDownError={setDropDownError}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group>
                      <Form.Label>
                        {t('transportation.Distance')}
                        {' '}
                        <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popover2}>
                          <FaInfoCircle />
                        </OverlayTrigger>
                      </Form.Label>
                      <div className="d-flex align-items-center">
                        <TextField object={transport} prop="totalDistance" onChange={handleInputChange(transport)} />
                        <span className="ml-2">km</span>
                      </div>
                    </Form.Group>
                  </Col>
                  {showNewForm(transport) ? (
                    <Col xs={2}>
                      <Form.Group>
                        <Form.Label>
                          {t('transportation.Total quantity')}
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <TextField
                            disabled={transport.transportMaterials.length > 0}
                            object={transport}
                            prop="totalQuantity"
                            onChange={handleInputChange(transport)}
                          />
                          <span className="ml-2">t</span>
                        </div>
                      </Form.Group>
                    </Col>
                  ) : (
                    <>
                      <Col xs={2}>
                        <Form.Group>
                          <Form.Label>
                            {t('transportation.Distance (road)')}
                          </Form.Label>
                          <div className="d-flex align-items-center">
                            <TextField object={transport} prop="roadDistancePercentage" onChange={handleInputChange(transport)} />
                            <span className="ml-2">%</span>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col xs={2}>
                        <Form.Group>
                          <Form.Label>
                            {t('transportation.Number of trips')}
                          </Form.Label>
                          {/* <TextField disabled={transport.transportMaterials.length > 0} object={transport} prop="numberOfTrips" onChange={handleInputChange(transport)} /> */}
                          <TextField disabled object={transport} prop="numberOfTrips" onChange={handleInputChange(transport)} />
                        </Form.Group>
                      </Col>

                      <Col xs={2}>
                        <Form.Group>
                          <Form.Label>
                            {t('transportation.Total quantity')}
                          </Form.Label>
                          <div className="d-flex align-items-center">
                            <TextField
                              disabled={transport.transportMaterials.length > 0}
                              object={transport}
                              prop="totalQuantity"
                              onChange={handleInputChange(transport)}
                            />
                            <span className="ml-2">t</span>
                          </div>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </>
              )}

              {transport.transportWaterborneId !== null && (
                <>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {t('database.Vessel')}
                      </Form.Label>
                      <DropDown
                        object={transport}
                        prop="transportWaterborneId"
                        onChange={handleInputChange(transport)}
                        options={vesselOptions()}
                        optiontext="longName"
                        setDropDownError={setDropDownError}
                        optionvalue="id" />
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group>
                      <Form.Label>
                        {t('transportation.Total distance')}
                        {' '}
                        <OverlayTrigger trigger="click" placement="right" overlay={popover2}>
                          <FaInfoCircle />
                        </OverlayTrigger>
                      </Form.Label>
                      <div className="d-flex align-items-center">
                        <TextField
                          object={transport}
                          prop="totalDistance"
                          onChange={handleInputChange(transport)}
                        />
                        <span className="ml-2">km</span>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group>
                      <Form.Label>
                        {t('transportation.Total quantity')}
                        {' '}
                      </Form.Label>
                      <div className="d-flex align-items-center">
                        <TextField
                          disabled={transport.transportMaterials.length > 0}
                          object={transport}
                          prop="totalQuantity"
                          onChange={handleInputChange(transport)}
                        />
                        <span className="ml-2">t</span>
                      </div>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>

            <Row>
              {/* <Col xs={2}>
                <Form.Group>
                  <Form.Label>
                    {t('Name of transport')}
                  </Form.Label>
                  <TextField object={transport} prop="name" onChange={handleInputChange(transport)} />
                </Form.Group>
              </Col> */}
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>
                    {t('common.Comment')}
                  </Form.Label>
                  <TextField object={transport} prop="description" onChange={handleInputChange(transport)} />
                </Form.Group>
              </Col>
            </Row>

            {transport.transportMaterials.length > 0 && (
              <h5 className="mt-2">
                {t('transportation.Transport materials')}
              </h5>)}
            {transport.transportMaterials.length > 0 && (
              <Row className="d-flex m-0 align-items-center">
                <em><strong style={{ color: '#009df0' }}>{t('common.Material search filters')}: </strong></em>
                <ButtonGroup className="d-flex align-items-center">
                  <ToggleButton
                    type="radio"
                    variant="link"
                    className={`custom-toggle-button ${selectedFilter[i] === 0 ? 'selected-filter' : ''}`}
                    name={`filter-${i}`}
                    value={0}
                    checked={selectedFilter[i] === 0}
                    onChange={() => handleFilterChange(i, 0)}
                  >
                    {t('common.All materials')}
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    variant="link"
                    className={`custom-toggle-button ${selectedFilter[i] === 1 ? 'selected-filter' : ''}`}
                    name={`filter-${i}`}
                    value={1}
                    checked={selectedFilter[i] === 1}
                    onChange={() => handleFilterChange(i, 1)}
                  >
                    {t('common.My materials')}
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    variant="link"
                    className={`custom-toggle-button ${selectedFilter[i] === 2 ? 'selected-filter' : ''}`}
                    name={`filter-${i}`}
                    value={2}
                    checked={selectedFilter[i] === 2}
                    onChange={() => handleFilterChange(i, 2)}
                  >
                    {t('common.Published materials')}
                  </ToggleButton>
                </ButtonGroup>
              </Row>
            )}
            {transport.transportMaterials.length > 0 && (
              <Table className="no-border-row">
                <thead>
                  <tr>
                    <th>{t('product.Material')}</th>
                    <th>
                      {t('common.Ratio')}
                      {' '}
                      <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popover3}>
                        <FaInfoCircle />
                      </OverlayTrigger>
                    </th>
                    <th>{t('common.Comment')}</th>
                    <th>
                      {t('common.Quantity')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transport.transportMaterials.map((transportMaterial, mi) =>
                  (
                    <Fragment key={`${i}-${mi}`}>
                      <tr>
                        <td>
                          <Typeahead
                            value={transportMaterial.material.name}
                            onSelect={(mat) => { handleTransportationMaterialSelect(transport, transportMaterial, mat); setErrors([]); }}
                            placeholder={
                              selectedFilter[i] === 1
                                ? t('common.Search my materials')
                                : selectedFilter[i] === 2
                                  ? t('common.Search published materials')
                                  : t('common.Search all materials')
                            }
                            source={(params) => searchMaterials({ ...params, filter: selectedFilter[i] })}
                            resultText="{name}, {unit}"
                            resultIcon={(res) => (res.createdBy > 0 ? 'user' : 'database')}
                            resultBackgroundColor={(res) => res.isLinked && '#d4edda'}
                          />
                        </td>
                        <td><TextField object={transportMaterial} prop="factor" onChange={handleInputChange(transportMaterial)} /></td>
                        <td><TextField object={transportMaterial} prop="comment" onChange={handleInputChange(transportMaterial)} /></td>
                        <td>
                          <div className="d-flex align-items-center">
                            <TextField disabled object={transportMaterial} prop="quantity" onChange={() => { }} />
                            <span className="ml-2">t</span>
                          </div>
                        </td>
                        <td>
                          <ButtonGroup className="float-right">
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('transportation.Edit transport material and conversions', 1))}>
                              <Button
                                variant="outline-info"
                                onClick={() => {
                                  setErrors([]);
                                  toggleMaterialForm(i, mi);
                                }}><FaPen /></Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('transportation.Delete transport material', 2))}>
                              <Button variant="outline-danger" onClick={() => { deleteMaterialFromTransport(transport, transportMaterial); }}><FaTrash /></Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                      {transportMaterial.material.name == "" ? (
                        <tr>
                          <td colSpan="8">
                            <Alert variant="warning">{t('product.Please select a material')}</Alert>
                          </td>
                        </tr>
                      ) : (
                        transportMaterial.conversionId <= 0 && element.unitId !== getUnitByCode('t', organizationId)?.[0]?.id && (
                          <tr>
                            <td colSpan="8">
                              <Alert variant="danger">
                                {t(`product.Conversion not present. Please add a material conversion from`) + ` '${getUnitById(element.unitId).symbol}' to 't'.`}
                              </Alert>
                            </td>
                          </tr>
                        ))}
                      {(transportMaterial.materialId > 0 && transportMaterial.conversionId != 0) && (
                        <tr>
                          <td colSpan="9">
                            <Alert variant="info">
                              <strong>{t('common.Conversion factor used')}:</strong> {`${getConversionFactor(transportMaterial.material.conversions, transportMaterial.conversionId)} ${'t'}/${getUnitById(element.unitId).symbol}`}
                              {getConversionComment(transportMaterial.material.conversions, transportMaterial.conversionId).trim() && (
                                <>
                                  &nbsp;&nbsp; - &nbsp;&nbsp; <strong>{t('common.Conversion comment')}:</strong> '{getConversionComment(transportMaterial.material.conversions, transportMaterial.conversionId)}'
                                </>
                              )}
                            </Alert>
                          </td>
                        </tr>
                      )}
                      {showMaterialForm === `${i}-${mi}` && (
                        <tr key={`material-${i}-${mi}`}>
                          <td colSpan="9">
                            <div className="material-form">
                              <MaterialForm
                                material={transportMaterial.material}
                                fromUnitId={element.unitId}
                                toUnitId={getUnitByCode('t', organizationId)?.[0]?.id}
                                onChange={(m) => setEditMaterial(m)}
                                isTransport
                                project={props.project}
                                organizationId={organizationId}
                                errors={errors}
                              />
                              <Button className="position-absolute r-1 b-1" onClick={() => handleSaveEditMaterial(transport, transportMaterial)}>{t('common.Save')}</Button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            )}
            <Button size="sm" variant="outline-primary" style={{ marginTop: '15px' }} onClick={() => { addNewMaterialToTransport(transport); }}>
              <FaPlus />
              {' '}
              {t('transportation.Add transport material')}
            </Button>
          </Card.Body>
        </Card>
      ))}
      {
        dropDownError &&
        <Row>
          <Col colSpan="11">
            <Alert variant='danger'>
              {t('transportation.Please select a Vehicle or Vessel from the drop down')}
            </Alert>
          </Col>
        </Row>
      }

      {transports.length === 0 ? (
        <Alert variant="primary" className="text-center p-5">
          <h2 className="h5">{t('transportation.No transports')}</h2>
          <Button variant="primary" onClick={handleAddTransportation}>
            <FaPlus />
            {' '}
            {t('transportation.Add transportation')}
          </Button>
        </Alert>
      ) : (
        <Button variant="outline-primary" onClick={handleAddTransportation}>
          <FaPlus />
          {' '}
          {t('transportation.Add transportation')}
        </Button>
      )}
    </>
  );
});
