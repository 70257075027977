import React, { useState, useEffect, useMemo } from 'react';
import {
  NavLink,
  Switch,
  Route,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Api from '../../services/api';
import Scenarios from './scenarios/Scenarios';
import Comparison from './results/Comparison';
import Results from './results/Results';
import ProjectHome from './ProjectHome';
import CarbonStocks from './carbon-stocks/CarbonStocks';
import './Project.css';
import { withSettingsStore } from '../common/settings-context';

const Project = withSettingsStore((props) => {
  const { t } = useTranslation();
  const projectId = props.match.params.id;
  const [project, setProject] = useState(null);
  const { currentRoles } = props;
  const [loading, setLoading] = useState(false);

  const getProject = async (id) => {
    setProject(await Api().projects(id).get({}));
  };

  // Retrieve project on mount
  useEffect(() => {
    getProject(projectId);
  }, [projectId]);

  const filteredRoles = currentRoles.filter(role => role.name === 'Admin' || role.projectId === parseInt(projectId));

  const routes = [
    {
      requiredRoles: ['Admin', 'ProjectReader', 'ProjectUser', 'ProjectAdmin'], key: 'project.Project', name: 'Project', to: `/project/${projectId}`, path: ['/project/:id'], component: () => (<ProjectHome id={projectId} project={project} />),
    },
    {
      requiredRoles: ['Admin', 'ProjectUser', 'ProjectAdmin'], key: 'scenario.Scenarios', name: 'Scenarios', to: `/project/${projectId}/scenarios`, path: ['/project/:id/scenarios'], component: () => (<Scenarios project={project} projectId={projectId} />),
    },
    {
      requiredRoles: ['Admin', 'ProjectUser', 'ProjectAdmin'], key: 'carbonstocks.Carbon stocks', name: 'Carbon stocks', to: `/project/${projectId}/carbon-stocks`, path: ['/project/:id/carbon-stocks'], component: () => (<CarbonStocks project={project} projectId={projectId} />),
    },
    {
      requiredRoles: ['Admin', 'ProjectReader', 'ProjectUser', 'ProjectAdmin'], key: 'comparison.Comparison', name: 'Comparison', to: `/project/${projectId}/compare`, path: ['/project/:id/compare'], component: () => (<Comparison project={project} projectId={projectId} />),
    },
    {
      requiredRoles: ['Admin', 'ProjectReader', 'ProjectUser', 'ProjectAdmin'], key: 'results.Results', name: 'Results', to: `/project/${projectId}/results`, path: ['/project/:id/results'], component: () => (<Results project={project} projectId={projectId} />),
    },
  ];

  // Provide accessible routes by user roles
  const accessibleRoutes = useMemo(() => routes.filter((r) => r.requiredRoles
    .map((role) => filteredRoles
    .map((cr) => cr.name).includes(role))
    .filter((i) => i === true).length > 0)
    .filter((r) => (r.name === 'Carbon stocks' && [1].includes(project?.organizationId)) || r.name !== 'Carbon stocks'), [currentRoles, project?.organizationId]);

  return (
    <div>
      <ul className="nav nav-tabs mb-2 px-2">
        {accessibleRoutes
          .map((r) => (
            <li className="nav-item" key={r.name}>
              <NavLink to={r.to} activeClassName="active" className="nav-link" exact>
                {t(r.key)}
              </NavLink>
            </li>
          ))}
      </ul>

    <Switch>
      {accessibleRoutes.map(route => (
        <Route
          key={route.key}
          path={route.path}
          render={route.component}
          exact
        />
      ))}
    </Switch>
    </div>
  );
});

export default Project;
