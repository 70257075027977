import React, {
  useState, useEffect, useCallback, Fragment, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import textEncoding from 'text-encoding';
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Form,
  Alert
} from 'react-bootstrap';
import { withSettingsStore } from '../../common/settings-context';
import Api from '../../../services/api';

import { humanize, saveAs } from '../../common/helpers';
import { DropDown, PageHeader } from '../../common/components';
import ScenarioCharts, {
  getBEmission,
  getCEmission,
  getPreConstructionEmission,
} from './ScenarioCharts';
import '../scenarios/Scenarios.scss';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
import Organizations from '../../../types/organizations';
ChartJS.register(...registerables);

export default withSettingsStore(({
  onChange, getUnitById, getProjectStatusById, projectId, isInRole, carbonStockLabels, user, getUnitByCode, 
}) => {
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState(false);
  const [project, setProject] = useState({});
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [projectOrgId, setProjectOrgId] = useState(null);
  const [Dropdown, setDropDownError] = useState(null);

console.log(projectId);
// console.log(project1);
  // Retrieve project 
  useEffect(() => {
    getProject(projectId);
  }, []);

  const getProject = async (id) => {
    const foundProject = (await Api().projects(id).get({}));
    setProjectOrgId(foundProject.organizationId)
  };

console.log(projectOrgId);

  const [allGraphs, setAllGraphs] = useState([
    'stacked_by_group',
    'doughnut_by_phase',
    'doughnut_by_group',
    'doughnut_by_product',
    'doughnut_by_carbon',
    'stacked_by_carbon',
    'emission_visualization',
  ])

  const [selectedGraphs, setSelectedGraphs] = useState([...allGraphs]);
  const handleGraphChange = (graphName, toggleState) => {
    if (selectedGraphs.includes(graphName) && toggleState === false) {
      setSelectedGraphs(selectedGraphs.filter((s) => s !== graphName));
    }
    if (!selectedGraphs.includes(graphName) && toggleState === true) {
      setSelectedGraphs(allGraphs.filter((g) => selectedGraphs.includes(g) || g === graphName));
    }
  };
  const childGroups = useMemo(() => {
    if (project === undefined || project.groups === undefined) {
      return [];
    }
    return project.groups.filter((g) => g.parentId !== null);
  }, [project]);

  const parentGroups = useMemo(() => {
    if (project === undefined || project.groups === undefined) {
      return [];
    }
    return project.groups.filter((g) => g.parentId === null);
  }, [project]);

  const hasHierarcy = useMemo(() => !(parentGroups.length > 0 && childGroups.length === 0), [childGroups.length, parentGroups.length]);
  const getReport = useCallback(async () => {
    setIsLoading(true);
    const report = await Api().reports().get({ projectId });
    setProject(report.project);
    setScenarios(report.scenarios);
    setSelectedScenario(report.scenarios[0]);
    
    // NOTE: This removes the option for carbon stock based results if the project isn't Finnish. If other geographies gain carbon stock access, this should be changed.
    if (report.project.organizationId != 1) {
      setAllGraphs(allGraphs.filter(name => !name.includes('carbon')));
    }

    setIsLoading(false);
  }, [projectId]);

  const handleInputChange = (obj) => (event) => {
    const { value } = event.currentTarget;
    const targetScenario = scenarios.find((v) => v.id === Number(value));
    if (targetScenario !== undefined) {
      setSelectedScenario({ ...targetScenario });
      onChange !== undefined && onChange(selectedScenario);
    }
  };

  const scenarioOptions = useCallback(() =>
    // Bundle vehicle
    scenarios.map((v) => {
      let longName = v.name;
      if (v.description !== '') {
        longName = `${longName} (${v.description})`;
      }
      return { ...v, longName };
    }),
    [scenarios]);

  const emissionUnits = [
    { id: 0, name: getUnitByCode('kgco2eq', projectOrgId)?.[0]?.symbol, factor: 1 },
    { id: 1, name: getUnitByCode('tco2eq', projectOrgId)?.[0]?.symbol, factor: 0.001 },
  ];

  const [selectedEmissionUnit, setSelectedEmissionUnit] = useState(emissionUnits[0]);

  useEffect(() => {
    setSelectedEmissionUnit(emissionUnits[0]);
  }
  , [emissionUnits.map(eu => eu.name).join()]
);

  const selectEmissionUnitById = (emissionUnitId) => {
    const emissionUnit = emissionUnits.find((u) => u.id === Number(emissionUnitId));
    if (emissionUnit !== undefined) {
      setSelectedEmissionUnit(emissionUnit);
    }
  };

  const getFactoredEmission = (emission) => {
    emission = Number(emission);
    emission *= selectedEmissionUnit.factor;
    return emission;
  };


  const getTransportTotalDistance = (element) => {
    if (element.transports.length > 0) {
      return element.transports.map((t) => t.totalDistance).join(',');
    }

    return 0;
  };

  //Export to CSV File
  const handleExport = (e) => {
    // Iterate groups
    const rows = [];
    rows.push(['', '', '', '', '', t('scenario.Partial emissions'), '', '', '', t('scenario.Total emissions'), '', '']);
    rows.push([t('common.Code'), t('common.Name'), t('transportation.Distance'), t('common.Quantity'), t('common.Unit'), t('product.Pre-construction'), t('product.Product'), t('transportation.Transport'), t('results.Work'), t('generic.BoundB'), t('generic.BoundC'), t('Abs.'), t('Abs. %'), t('common.Description')]);
    const groups = [...project.groups, { name: t('common.Ungrouped elements'), id: null }];
    groups.forEach((group) => {
      rows.push([group.code, group.name, group.description]);
      selectedScenario.elements.filter((e) => e.groupId === group.id).forEach((e) => {
        rows.push([e.code, e.name, getTransportTotalDistance(e), e.quantity, getUnitById(e.unitId).abbreviation, e.totalPreConstructionEmissions, e.totalProductEmission, e.totalTransportEmission, e.totalInstallationEmission, getBEmission(e), getCEmission(e), e.totalEmission, e.totalEmissionPercentage, e.description]);
      });

      rows.push(['', '', '', '', '',
        totalEmissions(group.id).preconstruction.toFixed(0),
        totalEmissions(group.id).product.toFixed(0),
        totalEmissions(group.id).transport.toFixed(0),
        totalEmissions(group.id).installation.toFixed(0),
        totalEmissions(group.id).totalB.toFixed(0),
        totalEmissions(group.id).totalC.toFixed(0),
        // totalEmissions(group.id).getBEmission(e).toFixed(0),
        // totalEmissions(group.id).getCEmission(e).toFixed(0),
        totalEmissions(group.id).totalEmission.toFixed(0),
        totalEmissions(group.id).totalEmissionPercentage.toFixed(0), '',
      ]);
    });
    rows.push([t('common.Total'), '', '', '', '',
    totalEmissions().preconstruction.toFixed(0),
    totalEmissions().product.toFixed(0),
    totalEmissions().transport.toFixed(0),
    totalEmissions().installation.toFixed(0),
    totalEmissions().totalB.toFixed(0),
    totalEmissions().totalC.toFixed(0),
    // totalEmissions().getBEmission(e).toFixed(0),
    // totalEmissions().getCEmission(e).toFixed(0),
    totalEmissions().totalEmission.toFixed(0),
    totalEmissions().totalEmissionPercentage.toFixed(0), '',
    ]);

    const delimiter = (user.organizationId === Organizations.FINLAND || user.organizationId ===Organizations.DENMARK) ? ';' : ',';
    // Convert arrays to csv string
    const str = rows.reduce((acc, cur) => [...acc, cur.map(value => `"${value}"`).join(delimiter)], []).join('\n');

    // Convert string to legacy version. Excel hack!
    const textEncoder = new textEncoding.TextEncoder('windows-1252', {
      NONSTANDARD_allowLegacyEncoding: true,
    });

    const blob = new Blob([textEncoder.encode([str])], { type: 'text/csv;charset=windows-1252;' });

    saveAs(blob, `report-${(`${project.name}_${selectedScenario.name}`).replace(/[^a-zA-Z0-9]/g, '_')}.csv`);
  };

  const totalEmissions = useCallback((groupId) => {
    if (selectedScenario === undefined || selectedScenario === null) {
      return {
        preconstruction: 0,
        product: 0,
        transport: 0,
        installation: 0,
        totalB: 0,
        totalC: 0,
        totalEmission: 0,
        totalPercentage: 0,
        totalEmissionPercentage: 0,
      };
    }

    const totals = selectedScenario.elements.filter((e) => e.groupId === groupId || groupId === undefined).reduce((acc, cur) => {
      acc.preconstruction += getPreConstructionEmission(cur);
      acc.product += cur.totalProductEmission;
      acc.transport += cur.totalTransportEmission;
      acc.installation += cur.totalInstallationEmission;
      acc.totalB += getBEmission(cur);
      acc.totalC += getCEmission(cur);
      acc.totalEmission += (getPreConstructionEmission(cur) + cur.totalProductEmission + cur.totalTransportEmission + cur.totalInstallationEmission + getBEmission(cur) + getCEmission(cur));
      // acc.totalEmission += cur.totalEmission + getBEmission(cur) + getCEmission(cur);
      acc.totalEmissionPercentage += cur.totalEmissionPercentage;
      return acc;
    }, {
      preconstruction: 0,
      product: 0,
      transport: 0,
      installation: 0,
      totalB: 0,
      totalC: 0,
      totalEmission: 0,
      totalEmissionPercentage: 0,
    });

    return totals;
  }, [selectedScenario]);

  useEffect(() => {
    if (!initialized) {
      getReport();
      setInitialized(true);
    }
  }, [initialized, getReport]);
  useEffect(() => {
    if (parentGroups.length > 0) {
      setSelectedGroup(parentGroups[0].id.toString());
    }
  }, [parentGroups]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {scenarios.length >= 1 ? (
            <Container fluid className="my-2">
              <PageHeader title={t('results.Results')}>
                {isInRole('ProjectAdmin', projectId) && (
                  <div className="header-buttons">
                    <Button onClick={handleExport}>{t('results.Export to csv')}</Button>
                  </div>
                )}
              </PageHeader>
              <Card>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col xs={8}>
                        <h2><strong>{project.name}</strong></h2>
                        <h3 className="h5">{project.description}</h3>
                      </Col>
                      <Col>
                        <h3 className="h5">{t('project.Project manager')}</h3>
                        <h4 className="h6">{project.managerName}</h4>
                      </Col>
                      <Col>
                        <h3 className="h5">{t('project.Status')}</h3>
                        <h4 className="h6">{t(`project.${getProjectStatusById(project.status)}`)}</h4>
                        {humanize.date(project.started, 'date')}
                        {' '}
                        -
                        {humanize.date(project.ended, 'date')}
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
                <hr />
                <Card.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        {project.groups && (
                          <Row>
                            <Col>
                              <h5><strong>{t('results.Groups')}</strong></h5>
                              <ul>
                                {/* {project.groups.map((group) => (
                                  <li key={group.id}>
                                    {[group.code, group.name, group.description].filter((g) => g !== null && g !== '').join(', ')}
                                  </li>
                                ))} */}
                                  {project.groups.map((group) => (
                                    <li key={group.id}>
                                      {[group.code, group.name === '' ? t('common.Unnamed group') : group.name, group.description]
                                        .filter((g) => g !== null && g !== '')
                                        .join(', ')}
                                    </li>
                                  ))}
                              </ul>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col>
                        <h5>
                          <strong>
                            {' '}
                            {t('scenario.Scenarios')}
                          </strong>
                        </h5>
                        {Array.isArray(scenarios) && scenarios.map((scenario) => (
                          <Row key={scenario.id}>
                            <Col sm={2}>
                              {scenario.name}
                            </Col>
                            <Col sm={10}>
                              {scenario.description}
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
                <hr />
                <Card.Body>
                  <Container fluid>
                    {selectedScenario !== null && (
                      <Row>
                        <Col>
                          <strong>
                            {t('results.Select scenario to be shown')}
                          </strong>
                          {Array.isArray(scenarios) && (
                            <DropDown
                              object={selectedScenario}
                              prop="id"
                              onChange={handleInputChange}
                              options={scenarioOptions()}
                              optiontext="{longName}"
                              optionvalue="id"
                              selectionRequired
                              setDropDownError={setDropDownError}
                            />
                          )}
                        </Col>
                      </Row>
                    )}
                    {selectedScenario && (
                      <Col>
                        <br />
                        <Row>
                          <strong>{t('results.Show charts')}</strong>
                        </Row>
                        <Row>
                          {allGraphs.map((graphName) => (
                            <Col key={graphName}>
                              <Form.Check
                                type="checkbox"
                                id={graphName}
                                name={graphName}
                                label={t(`results.${graphName}`)}
                                checked={selectedGraphs.includes(graphName)}
                                onChange={(e) => {
                                  handleGraphChange(graphName, e.currentTarget.checked);
                                }}
                              />
                            </Col>
                          ))}
                          <Col>
                            <Form.Check
                              type="checkbox"
                              id="check_table"
                              name="check_table"
                              label={t('results.Table')}
                              checked={showTable}
                              onChange={(e) => setShowTable(e.currentTarget.checked)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <hr />
                    <Row>
                      <Col md={(showTable && selectedGraphs.length > 0) ? 5 : 12}>
                        <Card>
                          <Button variant="info" className="no-border-radius" disabled>
                            {t('scenario.Scenario')}
                          </Button>
                          <Card.Img variant="top" />
                          <Card.Body>
                            {selectedScenario !== null && (
                              <ScenarioCharts
                                project={project}
                                groups={project.groups
                                  .filter((g) => g.parentId === Number(selectedGroup) || !hasHierarcy)}
                                scenario={selectedScenario}
                                graphs={selectedGraphs}
                                carbonStockLabels={carbonStockLabels}
                              />
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                      {showTable && selectedScenario && (
                        <Col md={selectedGraphs.length > 0 ? 7 : 12}>
                          <div className="table-container" style={{ maxHeight: '1000px', overflowY: 'auto' }}>
                            <Table className="table-sm">
                              <thead className="thead-light thead-light-border sticky-top">
                                <tr className="no-borders">
                                  {/* <th colSpan="3" className="light-blue" /> */}
                                  <th colSpan="20" className="light-blue text-center" style={{ color: 'white', backgroundColor: '#59a1ca' }} >
                                    {t('results.lifecycle_phase_a')}
                                  </th>
                                </tr>
                                <tr className="no-borders light-blue">
                                  <th colSpan="2" className="light-blue">
                                    <DropDown object={selectedEmissionUnit} prop="id" options={emissionUnits} onChange={(o) => (e) => { selectEmissionUnitById(e.currentTarget.value); }} optiontext="name" optionvalue="id" selectionRequired setDropDownError={setDropDownError} />
                                  </th>
                                  <th colSpan="5" className="light-blue" />

                                  <th colSpan="3" className="light-blue" style={{ color: 'white', backgroundColor: '#59a1ca' }}>
                                    {t('scenario.Partial emissions')}
                                    {' '}
                                    <small style={{ textTransform: 'none' }}>
                                    (
                                    {selectedEmissionUnit.name}
                                    )
                                    </small>
                                  </th>
                                  <th colSpan="8" className="light-blue text-center" style={{ color: 'white', backgroundColor: '#59a1ca' }}>
                                    {t('results.Boundary Stages B & C')}
                                    {' '}
                                    <small style={{ textTransform: 'none' }}>
                                    (
                                    {selectedEmissionUnit.name}
                                    )
                                    </small>
                                  </th>
                                  <th colSpan="2" className="light-blue text-center" style={{ color: 'white' }}>
                                    {t('scenario.Total emissions')}
                                    {' '}
                                    <small style={{ textTransform: 'none' }}>
                                    (
                                    {selectedEmissionUnit.name}
                                    )
                                    </small>
                                  </th>
                                </tr>
                                <tr className="no-borders">
                                  <th className="light-blue" style={{ color: 'white' }}>{t('common.Code')}</th>
                                  <th className="light-blue" style={{ color: 'white' }}>{t('common.Name')}</th>
                                  <th colSpan="2" className="text-center light-blue" style={{ color: 'white' }}>{t('transportation.Distance')}</th>
                                  <th colSpan="2" className="text-center light-blue" style={{ color: 'white' }}>{t('common.Quantity')}</th>
                                  <th className="text-center light-blue" style={{ color: 'white' }}>{t('product.Pre-construction')}</th>
                                  <th className="text-center light-blue" style={{ color: 'white' }}>{t('product.Product')}</th>
                                  <th className="text-center light-blue" style={{ color: 'white' }}>{t('transportation.Transport')}</th>
                                  <th className="text-center light-blue" style={{ color: 'white' }}>{t('results.Work')}</th>
                                  <th colSpan="3" className="text-center light-blue" style={{ color: 'white' }}>{t('generic.BoundB')}</th>
                                  <th colSpan="5" className="text-center light-blue" style={{ color: 'white' }}>{t('generic.BoundC')}</th>
                                  <th className="text-center light-blue" style={{ color: 'white' }}>{t('Abs.')}</th>
                                  <th className="text-center light-blue" style={{ color: 'white' }}>{t('Abs. %')}</th>
                                  {/* <th className="light-blue" /> */}
                                  {/* <th className="light-blue">{t('common.Description')}</th> */}
                                  {/* <th className="light-blue" /> */}
                                </tr>
                              </thead>
                              {project.groups !== undefined && [...project.groups.filter((g) => g.parentId === Number(selectedGroup) || !hasHierarcy), { name: t('common.No group'), id: null }].map((p) => (
                                <Fragment key={p.id}>
                                  <thead className="thead-light" > {/* Group name row */}
                                    <tr>
                                      <th colSpan="1">
                                        <em>{p.code}</em>
                                      </th>
                                      <th colSpan="19" className="" >
                                        <em>{p.name === ''? t('common.Unnamed group') : p.name}</em>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.isArray(selectedScenario.elements) && selectedScenario.elements.map((item) => item.groupId === p.id && (
                                      <tr key={item.id}> {/* Element row */}
                                        {item.code !== null && item.code !== "null" ?
                                          <td>{item.code}</td> : <td></td>}
                                        <td>{item.name}</td>
                                        <td className="text-right">
                                          {getTransportTotalDistance(item)}
                                        </td>
                                        <td className="info-color">km</td>
                                        <td className="text-right">
                                          {' '}
                                          {item.quantity}
                                        </td>
                                        <td>{getUnitById(item.unitId).abbreviation}</td>
                                        <td className="text-center text-nowrap">{getPreConstructionEmission(item) && humanize.amount_long(getFactoredEmission(getPreConstructionEmission(item)), selectedEmissionUnit.id)}</td>
                                        <td className="text-center text-nowrap">{item.totalProductEmission && humanize.amount_long(getFactoredEmission(item.totalProductEmission), selectedEmissionUnit.id)}</td>
                                        <td className="text-center text-nowrap">{item.totalTransportEmission && humanize.amount_long(getFactoredEmission(item.totalTransportEmission), selectedEmissionUnit.id)}</td>
                                        <td className="text-center text-nowrap">{item.totalInstallationEmission && humanize.amount_long(getFactoredEmission(item.totalInstallationEmission), selectedEmissionUnit.id)}</td>
                                        <td colSpan="3" className="text-center text-nowrap">{getBEmission(item) && humanize.amount_long(getFactoredEmission(getBEmission(item)), selectedEmissionUnit.id).toString()}</td>
                                        <td colSpan="5" className="text-center text-nowrap">{getCEmission(item) && humanize.amount_long(getFactoredEmission(getCEmission(item)), selectedEmissionUnit.id)}</td>
                                        {/* <td className="text-right text-nowrap"><strong>{item.totalEmission && humanize.amount_long(getFactoredEmission(item.totalEmission), selectedEmissionUnit.id)}</strong></td> */}
                                        <td className="text-right text-nowrap"><strong>{(getPreConstructionEmission(item) + item.totalProductEmission + item.totalTransportEmission + item.totalInstallationEmission + getBEmission(item) + getCEmission(item)) && humanize.amount_long(getFactoredEmission(getPreConstructionEmission(item) + item.totalProductEmission + item.totalTransportEmission + item.totalInstallationEmission + getBEmission(item) + getCEmission(item)), selectedEmissionUnit.id)}</strong></td>
                                        <td className="text-right text-nowrap">
                                          <strong>
                                            {item.totalEmissionPercentage.toFixed(2)}
                                            {' '}
                                            %
                                          </strong>
                                        </td>
                                        {/* <td colSpan="3">{item.description}</td> */}
                                      </tr>
                                    ))}
                                    <tr />
                                    <tr> {/* Group total row */}
                                      <td className="thead-light"style={{ backgroundColor: '#FAFAFA' }}><em><strong>{t('common.Group total')}</strong></em></td>
                                      <td colSpan="5" style={{ backgroundColor: '#FAFAFA' }}/>
                                      <td className="text-center text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).preconstruction), selectedEmissionUnit.id)}</strong></em></td>
                                      <td className="text-center text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).product), selectedEmissionUnit.id)}</strong></em></td>
                                      <td className="text-center text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).transport), selectedEmissionUnit.id)}</strong></em></td>
                                      <td className="text-center text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).installation), selectedEmissionUnit.id)}</strong></em></td>
                                      <td colSpan="3" className="text-center text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).totalB), selectedEmissionUnit.id)}</strong></em></td>
                                      <td colSpan="5" className="text-center text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).totalC), selectedEmissionUnit.id)}</strong></em></td>
                                      <td className="text-right text-nowrap" style={{ backgroundColor: '#FAFAFA' }}><em><strong>{humanize.amount_long(getFactoredEmission(totalEmissions(p.id).totalEmission), selectedEmissionUnit.id)}</strong></em></td>
                                      <td className="text-right text-nowrap" style={{ backgroundColor: '#FAFAFA' }}>
                                        <em><strong>
                                          {totalEmissions(p.id).totalEmissionPercentage.toFixed(2)}
                                          {' '}
                                          %
                                        </strong></em>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Fragment>
                              ))}
                              <tfoot>
                                <tr> {/* Scenario total row */}
                                  <td colSpan="6" style={{ backgroundColor: '#E6E6E6' }}>
                                    <strong>{t('common.Scenario total')}</strong>
                                  </td>
                                  <td className="text-center text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().preconstruction), selectedEmissionUnit.id)}</strong></td>
                                  <td className="text-center text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().product), selectedEmissionUnit.id)}</strong></td>
                                  <td className="text-center text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().transport), selectedEmissionUnit.id)}</strong></td>
                                  <td className="text-center text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().installation),selectedEmissionUnit.id)}</strong></td>
                                  <td colSpan="3" className="text-center text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().totalB), selectedEmissionUnit.id)}</strong></td>
                                  <td colSpan="5" className="text-center text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().totalC), selectedEmissionUnit.id)}</strong></td>
                                  <td className="text-right text-nowrap" style={{ backgroundColor: '#E6E6E6' }}><strong>{humanize.amount_long(getFactoredEmission(totalEmissions().totalEmission), selectedEmissionUnit.id)}</strong></td>
                                  <td className="text-right text-nowrap" style={{ backgroundColor: '#E6E6E6' }}>
                                    <strong>
                                      {totalEmissions().totalEmissionPercentage.toFixed(0)}
                                      {' '}
                                      %
                                    </strong>
                                  </td>
                                  <td colSpan="1" />
                                </tr>
                              </tfoot>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Container>
          ) : (
            <Alert variant="primary" className="text-center p-5 m-3">
              <h2 className="text-center h4 m-0">{t('comparison.At least 2 scenarios required')}</h2>
            </Alert>
          )}
        </>
      )}
    </>
  );

});
